import { DocumentType, FeatureType } from '@innedit/innedit-type';
import { FeatureData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemFeature: FC<ListItemProps<FeatureType, FeatureData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [isOpen] = useState<boolean>(true);
  const [doc, setDoc] = useState<DocumentType<FeatureType>>();
  const [docs, setDocs] = useState<DocumentType<FeatureType>[]>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  useEffect(() => {
    let isMounted = true;
    if (isOpen && doc && doc.nbChildren && doc.nbChildren > 0) {
      const subModel = new FeatureData({
        espaceId: model.espaceId,
      });
      subModel
        .find({
          orderDirection: 'desc',
          orderField: 'datetime',
          wheres: {
            parent: docId,
          },
        })
        .then(newDocs => {
          if (isMounted) {
            setDocs(newDocs);
          }

          return true;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [doc, isOpen]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={
        [
          // {
          //   iconLeft: IconAdd,
          //   to: `/espaces/${doc.espaceId}/params/features/create?parent=${docId}`,
          // },
        ]
      }
      after={
        docs &&
        docs.length > 0 && (
          <div className="pl-6 bg-light-400">
            {docs.map(d => (
              <ListItemFeature
                key={d.id}
                docId={d.id}
                model={model}
                onClick={onClick}
              />
            ))}
          </div>
        )
      }
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemFeature;
